<template>
  <div>
    <v-alert
      v-if="
        checkPermission(['notify_order_instant']) && instantOrders.length > 0
      "
      color="warning"
      text
      dense
      border="left"
      style="height: 40px"
    >
      <b>{{ instantOrders.length }}</b>
      {{ $t("labels.express_noti") }}. {{ $t("labels.delivery_company_1") }}:
      <b>{{ orderDelivery }}</b>
    </v-alert>

    <div v-for="(zone, zk) in activeZones" :key="`z_${zone.zone}`">
      <template v-if="zone.total > 0">
        <v-divider
          v-if="zk > 0"
          class="my-3"
          style="border-color: #9c27b0"
        ></v-divider>
        <div class="text-h6 text-center purple--text" v-if="zones.length > 2">
          {{ zone.zone === "Multi" ? "Multi Zone" : `Zone ${zone.zone}` }}
        </div>
        <v-simple-table class="pickup-table">
          <template v-slot:default>
            <tbody>
              <tr
                v-for="item in zone.items"
                :key="`p_${item.key}`"
                :class="{
                  'info white--text font-weight-bold':
                    filters.pickup_type === item.key &&
                    filters.zone === zone.zone,
                  'd-none': !item.count,
                }"
              >
                <td
                  @click="setPickupType(item.key, zone.zone, zone.items)"
                  class="cursor-pointer"
                >
                  {{ $t(`labels.${item.display}`) }}
                </td>
                <td>
                  <span
                    class="cursor-link"
                    @click="showOrderDialog(item.key, zone.zone)"
                    >{{ item.count }}</span
                  >
                </td>
                <td>
                  <v-icon
                    v-if="
                      filters.pickup_type === item.key &&
                      filters.zone === zone.zone
                    "
                    class="white--text"
                    >mdi-checkbox-marked</v-icon
                  >
                  <v-icon
                    v-else
                    @click="setPickupType(item.key, zone.zone, zone.items)"
                    class="cursor-pointer"
                    >mdi-checkbox-blank-outline</v-icon
                  >
                </td>
              </tr>
              <tr>
                <td class="text-center">{{ $t("labels.sum") }}</td>
                <td colspan="2">
                  <span
                    class="cursor-link error--text font-weight-medium"
                    @click="showOrderDialog(null, zone.zone)"
                  >
                    <small v-if="is_cutoff"
                      >({{ $t("labels.mandatory") }})</small
                    >
                    {{ totalCount(zone.items) }} </span
                  >/{{ zone.total }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </template>
    </div>

    <v-row dense>
      <v-col cols="6">
        <v-select
          :items="sourceOptions"
          v-model="filters.source"
          @change="getConfig"
          class="c-input-small"
          :label="$t('labels.order_all')"
          dense
          outlined
          clearable
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="filters.tracking_id"
          class="c-input-small"
          :label="$t('labels.detail_tracking_code')"
          dense
          outlined
          clearable
          hide-details
          @keyup.enter="getOrder"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-select
          :items="deliveryCompanyOptions"
          v-model="filters.id_delivery_company"
          @change="getConfig"
          class="c-input-small"
          :label="$t('labels.delivery_company_all')"
          dense
          outlined
          clearable
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="6">
        <v-select
          :items="customerOptions"
          v-model="filters.id_customers"
          @change="getConfig"
          class="c-input-small"
          :label="$t('labels.customer_all')"
          dense
          outlined
          clearable
          hide-details
          multiple
        ></v-select>
      </v-col>
      <v-col cols="12">
        <v-autocomplete
          :items="shopOptions"
          v-model="filters.id_customer_e_marketplace_shop"
          @change="getConfig"
          class="c-input-small"
          :label="$t('labels.shop')"
          dense
          outlined
          clearable
          hide-details
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" v-if="scannedOrders.length > 0">
        <v-chip
          class="mr-1 mb-1"
          v-for="od in scannedOrders"
          :key="od.tracking_id"
        >
          {{ od.tracking_id }}
        </v-chip>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6" v-if="myWarehouses.length > 1">
        <v-btn color="warning" block @click="backStep">
          {{ $t("labels.back") }}
        </v-btn>
      </v-col>
      <v-col :cols="myWarehouses.length > 1 ? 6 : 12">
        <v-btn color="success" block @click="nextStep" :disabled="isDisabled">
          {{ $t("labels.next") }}
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="orderDialog" max-width="1366px">
      <v-card>
        <v-card-title class="mb-3">
          <span class="text-h6">{{ $t("labels.detail") }}</span>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="hideOrderDialog">
            {{ $t("labels.close") }}
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-simple-table class="table-padding-2">
            <template v-slot:default>
              <thead class="v-data-table-header">
                <tr>
                  <th class="text-center">{{ $t("labels.warehouse") }}</th>
                  <th class="text-center">{{ $t("labels.tracking") }}</th>
                  <th class="text-center">{{ $t("labels.customer") }}</th>
                  <th class="text-center">
                    {{ $t("labels.customer_order_id") }}
                  </th>
                  <th class="text-center">{{ $t("labels.e_market") }}</th>
                  <th class="text-center">{{ $t("labels.shop") }}</th>
                  <th class="text-center">
                    {{ $t("labels.delivery_company") }}
                  </th>
                  <th class="text-center">
                    {{ $t("labels.delivery_order_id") }}
                  </th>
                  <th class="text-center">{{ $t("labels.order_create_1") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="order in orders" :key="order.id">
                  <td class="text-center">{{ order.warehouse_code }}</td>
                  <td class="text-center">
                    <OrderTracking :tracking-id="order.tracking_id" />
                  </td>
                  <td class="text-center">{{ order.company_name }}</td>
                  <td class="text-center">{{ order.customer_order_id }}</td>
                  <td class="text-center">{{ order.e_marketplace_name }}</td>
                  <td class="text-center">{{ order.shop_name }}</td>
                  <td class="text-center">{{ order.delivery_company }}</td>
                  <td class="text-center">{{ order.delivery_order_id }}</td>
                  <td class="text-center">
                    {{ formatDateTime(order.create_timestamp) }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="qrScanDialog" max-width="100vw">
      <QRCodeScanner
        v-if="qrScanDialog"
        :name="qrScanType"
        :auto-close="false"
        @close="hideQRCodeScan"
        @onScanned="onQRScanned"
      />
    </v-dialog>
  </div>
</template>

<script>
import { debounce, formatDateTime } from "@/libs/helpers";
import { httpClient } from "@/libs/http";

export default {
  components: {
    OrderTracking: () => import("@/components/common/OrderTracking"),
    QRCodeScanner: () => import("@/components/common/QRCodeScanner"),
  },
  name: "PickupStep2",
  props: {
    selected: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    orders: [],
    instantOrders: [],
    instantDeliveries: [],
    orderDialog: false,
    filters: {},
    sourceOptions: [],
    deliveryCompanyOptions: [],
    customerOptions: [],
    shops: [],
    scannedOrders: [],
    zones: [],
    is_cutoff: false,
    qrScanDialog: false,
    qrScanType: null,
    cutoff_time: null,
  }),
  created() {
    this.filters = { ...this.selected };
  },
  computed: {
    activeZones() {
      return [...this.zones].filter((zone) => zone.total > 0);
    },
    isDisabled() {
      return (
        !this.filters.pickup_type &&
        this.filters.pickup_type !== 0 &&
        this.scannedOrders.length === 0
      );
    },
    orderIds() {
      return [...this.scannedOrders].map((o) => o.id);
    },
    myWarehouses() {
      if (!window.me || !window.me.warehouses) {
        return [];
      }
      return window.me.warehouses;
    },
    orderDelivery() {
      if (!this.instantOrders || this.instantOrders.length === 0) {
        return "";
      }

      const orderGroup = {};
      for (let i = 0; i < this.instantOrders.length; i++) {
        const order = this.instantOrders[i];
        const delivery = [...this.instantDeliveries].find(
          (de) => de.id == order.id_delivery_company
        );
        if (!orderGroup[delivery.short_name]) {
          orderGroup[delivery.short_name] = [];
        }
        orderGroup[delivery.short_name].push(order.tracking_id);
      }

      const deliveryKeys = Object.keys(orderGroup);
      const views = [];
      for (let i = 0; i < deliveryKeys.length; i++) {
        const deliveryKey = deliveryKeys[i];
        views.push(`${deliveryKey} - ${orderGroup[deliveryKey].length}`);
      }

      return views.join("; ");
    },
    shopOptions() {
      let shops = [...this.shops];

      if (this.filters.source) {
        shops = [...shops].filter(
          (s) => this.filters.source == s.id_e_marketplace
        );
      }

      return [...shops].map((e) => {
        const eMarket = this.$t(`labels.e_market_${e.id_e_marketplace}`);
        return {
          value: e.id,
          text: `${eMarket}: ${e.display_name} (${e.count_order})`,
        };
      });
    },
  },
  mounted() {
    this.getConfig();
    this.$root.$on("onPickupUpdated", this.getConfig);
  },
  beforeDestroy() {
    this.$root.$off("onPickupUpdated", this.getConfig);
  },
  methods: {
    showQRCodeScan(type) {
      this.qrScanDialog = true;
      this.qrScanType = type;
    },
    hideQRCodeScan() {
      this.qrScanDialog = false;
      this.qrScanType = null;
    },
    onQRScanned(filter) {
      this[filter.name] = filter.value;
      this.getOrder();
    },
    formatDateTime,
    totalCount(items) {
      let total = 0;
      if (!items || items.length === 0) {
        return total;
      }
      items.forEach((item) => {
        total += item.count;
      });
      return total;
    },
    getConfig: debounce(function () {
      httpClient
        .post("/goods-issue-detail/v1/pickup-quantity", { ...this.filters })
        .then(({ data }) => {
          this.zones = [...data.groupByZones];
          this.is_cutoff = data.is_cutoff;
          this.cutoff_time = data.cutoff;

          this.getDeliveryCompanies();
          this.getShops();
          this.getCustomers();
          this.getSources();

          this.checkMissingData();
        });
    }, 500),
    checkMissingData() {
      let totalByZone = 0
      let totalByType = 0
      for (let i = 0; i < this.zones.length; i++) {
        const zone = this.zones[i];
        totalByZone += +zone.total

        for (let j = 0; j < zone.items.length; j++) {
          const item = zone.items[j];
          totalByType += +item.count
        }
      }

      if (totalByZone > 0 && totalByType === 0) {
        this.$vToastify.error(this.$t("messages.pickup_need_process"));
      }

      return true
    },
    getOrders(key, zone) {
      httpClient
        .post("/goods-issue-detail/v1/pickup-list-orders", {
          ...this.filters,
          pickup_type: key,
          zone,
        })
        .then(({ data }) => {
          this.orders = [...data];
        });
    },
    getInstantOrders() {
      /* if (!this.checkPermission(["notify_order_instant"])) {
        return false;
      } */
      httpClient
        .post("/goods-issue-detail/v1/get-order-instant", { ...this.filters })
        .then(({ data }) => {
          this.instantOrders = [...data.orders];
          this.instantDeliveries = [...data.deliveries];
          const instantOrderDeliveries = [...this.instantOrders].map(
            (io) => io.id_delivery_company
          );
          if (instantOrderDeliveries.length > 0) {
            for (let i = 0; i < instantOrderDeliveries.length; i++) {
              const idDelivery = instantOrderDeliveries[i];
              const delivery =
                this.instantDeliveries.find((isDe) => isDe.id == idDelivery) ||
                {};
              const checkDelivery = this.deliveryCompanyOptions.find(
                (dc) => dc.value == delivery.id
              );
              if (!checkDelivery) {
                const checkOrders = this.instantOrders.filter(
                  (io) => io.id_delivery_company == delivery.id
                );
                this.deliveryCompanyOptions.push({
                  value: delivery.id,
                  text: `${delivery.short_name} (${checkOrders.length})`,
                });
              }
            }
          }
        });
    },
    async getOrder() {
      if (!this.filters.tracking_id) {
        return false;
      }

      const checkOrder = [...this.scannedOrders].find(
        (so) =>
          so.tracking_id.toUpperCase() ===
          this.filters.tracking_id.toUpperCase()
      );
      if (checkOrder) {
        this.filters = { ...this.filters, tracking_id: null };
        this.$vToastify.success(this.$t("messages.order_scanned"));
        document.getElementById("error_sound_player").play();
        return false;
      }

      if (this.isLoading) {
        this.filters = { ...this.filters, tracking_id: null };
        this.$vToastify.warning(this.$t("messages.loading"));
        document.getElementById("error_sound_player").play();
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post(
          "/goods-issue-detail/v1/pickup-get-order-by-tracking",
          { ...this.filters }
        );
        const checkOrder = [...this.scannedOrders].find(
          (so) => so.id == data.id
        );
        if (checkOrder) {
          this.isLoading = false;
          this.filters = { ...this.filters, tracking_id: null };
          document.getElementById("error_sound_player").play();
          this.$vToastify.error(this.$t("messages.order_scanned"));
          return false;
        }
        this.scannedOrders.push(data);
        document.getElementById("success_sound_player").play();
        this.isLoading = false;
        this.filters = { ...this.filters, tracking_id: null };
      } catch (e) {
        this.isLoading = false;
        this.filters = { ...this.filters, tracking_id: null };
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        document.getElementById("error_sound_player").play();
        this.$vToastify.error(errMsg);
      }
    },
    showOrderDialog(key, zone) {
      this.orderDialog = true;
      this.orders = [];
      this.getOrders(key, zone);
    },
    hideOrderDialog() {
      this.orderDialog = false;
    },
    setPickupType(key, zone, items) {
      const config = items.find((i) => i.key === key);
      if (config.count > 0) {
        this.filters = { ...this.filters, pickup_type: key, zone };
      }
    },
    backStep() {
      this.$emit("onUpdate", {
        ...this.filters,
        id_warehouse: null,
        pickup_type: null,
        zone: null,
        order_ids: [],
        scannedOrders: [],
      });
    },
    nextStep() {
      let config = {};
      const zone = this.zones.find((z) => z.zone === this.filters.zone);
      if (zone) {
        config = zone.items.find((i) => i.key === this.filters.pickup_type);
      }
      this.$emit("onUpdate", {
        ...this.filters,
        config,
        order_ids: this.orderIds,
        scannedOrders: this.scannedOrders,
      });
    },
    async getDeliveryCompanies() {
      const { data } = await httpClient.post(
        "/common/v1/get-delivery-by-state",
        {
          state: 40,
          id_warehouse: this.selected.id_warehouse,
          is_wait_pickup: true,
          cutoff_time: this.cutoff_time,
        }
      );
      this.deliveryCompanyOptions = [...data].map((e) => ({
        value: e.id_delivery_company,
        text: `${e.delivery_company} (${e.count_order})`,
      }));
      this.getInstantOrders();
    },
    async getCustomers() {
      const { data } = await httpClient.post(
        "/common/v1/get-customer-by-state",
        {
          state: 40,
          id_warehouse: this.selected.id_warehouse,
          is_wait_pickup: true,
          cutoff_time: this.cutoff_time,
        }
      );
      this.customerOptions = data.map((e) => ({
        value: e.id_customer,
        text: `${e.company_name} (${e.count_order})`,
      }));
    },
    async getSources() {
      const { data } = await httpClient.post("/common/v1/get-source-by-state", {
        state: 40,
        id_warehouse: this.selected.id_warehouse,
        is_wait_pickup: true,
        cutoff_time: this.cutoff_time,
      });
      this.sourceOptions = data.map((e) => ({
        value: e.id_e_marketplace || 0,
        text: `${this.$t(`labels.order_source_${e.id_e_marketplace}`)} (${
          e.count_order
        })`,
      }));
    },
    async getShops() {
      const { data } = await httpClient.post("/common/v1/get-shop-by-state", {
        state: 40,
        id_warehouse: this.selected.id_warehouse,
        is_wait_pickup: true,
        cutoff_time: this.cutoff_time,
      });
      this.shops = [...data];
    },
  },
};
</script>
